import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import PageViewEvent from 'website/components/PageViewEvent'
import './static/fonts/fonts.scss'
import RootElement from 'website/Context/RootElement'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  if (props?.path?.endsWith('--nojs/')) {
    return <>{element}</>
  }

  /* ### Optanon OneTrust integration
   * `type="text/plain"` effectively disables these script tags, unless cookie
   * consent is provided in OneTrust for the corresponding categories.
   * otSDKStub.js will find this script using the `optanon-category-{CATEGORY}`
   * class and change the type to text/javascript when consent is provided.
   *
   * The categories are:
   * - C0001: strictly necessary
   * - C0002: performance
   * - C0003: functional
   * - C0004: targeting
   * - C0005: social
   *
   * More information on how this works can be found in the OneTrust documentation:
   * https://my.onetrust.com/s/article/UUID-518074a1-a6da-81c3-be52-bae7685d9c94?language=en_US
   */

  return (
    <RootElement>
      <PageViewEvent />
      {element}
    </RootElement>
  )
}

declare global {
  interface Window {
    initialRenderComplete: boolean
  }
}

export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] = () => {
  window.initialRenderComplete = true
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps: { location } }) => {
  const { disableScrollUpdate } = location.state ?? false
  return !disableScrollUpdate
}
