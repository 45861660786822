import React, { FC, useEffect } from 'react'
import { sendPageViewEvent } from 'website/events/pageView'
import { pushInitialEvents } from 'website/events/dataLayer'

const PageViewEvent: FC<{}> = () => {
  useEffect(() => {
    void sendPageViewEvent()
    void pushInitialEvents()
  }, [])

  return <React.Fragment />
}

export default PageViewEvent
