import { createAndSendEvent } from './apiGatewayService'

interface Data {
  referrer: string
}

export const sendPageViewEvent = async (): Promise<void> => {
  const pageViewedData: Data = {
    referrer: document.referrer
  }

  return createAndSendEvent<Data>('PageViewed', pageViewedData)
}
