import { useQuery } from '@tanstack/react-query'

const windowCheck = () => typeof window !== 'undefined'

const getAuthStatus = async () => {
  try {
    const data = await fetch('/api/token/')
    const jsonData = await data.json()
    if (jsonData?.accessToken && windowCheck() && typeof data === 'object') {
      return jsonData.accessToken
    }
    return false
  } catch (err) {
    return false
  }
}

export const checkAuth = () => {
  return useQuery({
    queryKey: ['authStatus'],
    queryFn: () => getAuthStatus()
  })
}

export const checkRegion = () => {
  const req = new XMLHttpRequest()
  req.open('GET', document.location.toString(), false)
  req.send(null)
  return req.getResponseHeader('country-code') || 'US'
}

export const checkCountry = () => {
  return useQuery({
    queryKey: ['countryCode'],
    queryFn: () => checkRegion()
  })
}

export const isSignedIn = async () => {
  if (windowCheck() && (await getAuthStatus())) {
    return true
  }
  return false
}
