import { useEffect, useState } from 'react'

export function useIsBrowser(): boolean {
  const [isBrowser, setIsBrowser] = useState<boolean>(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  return isBrowser
}

const isBrowser = typeof window !== 'undefined'

export default isBrowser
