import { isSignedIn } from 'website/utils/session'
import isBrowser from 'website/animations/utils/isBrowser'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

export const pushEvent = (event: any) => isBrowser && window.dataLayer.push(event)

export const pushLogoutEvent = () => pushEvent({ is_logged_in: false })

export const pushInitialEvents = async () => {
  if (!isBrowser) {
    return
  }

  if (window.location.pathname === '/') {
    pushEvent({ page: 'home' })
  }

  if (await isSignedIn()) {
    pushEvent({ is_logged_in: true })
  } else {
    pushEvent({ is_logged_in: false })
  }
}
