import EventPublisher from '@linktr.ee/event-lib/dist/client'
export interface EventContext {
  url: string
  language: string
  userAgent: string
  referrer: string
  channel: string
}

export type EventName = 'PageViewed' | 'WebsiteElementClicked'

export const buildPayloadContext = (): EventContext => {
  return {
    url: window.location.href,
    language:
      navigator.languages && navigator.languages[1]
        ? navigator.languages[1]
        : navigator.language
        ? navigator.language
        : 'en',
    userAgent: navigator.userAgent,
    referrer: document.referrer,
    channel: 'web'
  }
}

/*
 *  This function will accept generic LTEvents, transparently add profile-specific context,
 *  and push the updated payload to the client-event-producer which will forward it on to EB.
 */

type GenericIdentityEvent<D> = {
  browserId: string
  id: string
  createdAt: Date
  context: EventContext
  eventType: string
  data: D
}

export type ClientSideIdentityEvent<D> = Omit<GenericIdentityEvent<D>, 'browserId' | 'id' | 'createdAt'>

export const createAndSendEvent = <D>(eventName: EventName, event: D, tags?: string[]): Promise<void> => {
  const firstHalfEndpoint = process.env.GATSBY_IDENTITY_API_ENDPOINT.substr(
    0,
    Math.floor(process.env.GATSBY_IDENTITY_API_ENDPOINT.length / 2)
  )
  const secondHalfEndpoint = process.env.GATSBY_IDENTITY_API_ENDPOINT.substr(
    Math.floor(process.env.GATSBY_IDENTITY_API_ENDPOINT.length / 2),
    process.env.GATSBY_IDENTITY_API_ENDPOINT.length
  )
  const deobfuscatedEndpoint = [secondHalfEndpoint.split('').reverse().join(''), firstHalfEndpoint].join('')

  const pub = EventPublisher({ endpoint: deobfuscatedEndpoint })

  // make Identity Event
  const identityEvent: ClientSideIdentityEvent<D> = {
    eventType: eventName,
    context: buildPayloadContext(),
    data: event
  }
  return pub.publish(eventName, identityEvent, tags || [])
}
